
.auth_page{
  background-image: url("../src//images//banner.png");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover; 

  min-height: 100vh;
  display: grid;
  place-items: center;

}

.card {
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,.35);
    display: grid;
    min-width: 33%;
    padding: 2rem 4rem;
    place-items: center;
}
.ant-input-prefix{
color: #257531;
font-weight: bold;
font-size: 1.4rem;
}
.navTitle{
  color: #00668a;
  font-size: 1.5rem;
  font-weight: bold;
  
}
.Donate_form input{
background-color: #71717a00;
}
.Donate_form .ant-input-affix-wrapper{
background-color: #71717a29;
}
.input__height {
  min-height: 3rem;
  border: none;
}
.card_name{
  background-color: #71717a29 !important;
}
.auth_button{
  background: linear-gradient(270deg,#00668a,#257531);
  color: white;
  font-weight: 2rem;
  padding: 10px 20px 34px;
  width: 14rem;
  border-radius: 20px;
  font-weight: bold;
}
.auth_button_ew{
  background: linear-gradient(270deg,#face20,#f79e1e);
  color: white;
  font-weight: 2rem;
  padding: 10px 20px 34px;
  width: 14rem;
  border-radius: 20px;
  font-weight: bold;
}
/* .ant-input{
} */
.ant-input-affix-wrapper >input.ant-input {
  background-color: #F1F1F1;

}